<template>
  <div class="container content">
    <div v-html="contents.page.mainContent"></div>
    <div class="row">
      <div class="col-6">
        <form  @submit.prevent="submitHandle(1)">
          <label for="uploadFile" class="form-label">PDF document*</label>
          <div class="input-group">
            <input type="file" class="form-control" ref="uploadFile" v-on:change="handleFileUpload()">
          </div>
          <div v-if="uploadFileError" class="small text-danger">
            {{ uploadFileError }}
          </div>
          <div class="form-group mt-3 mb-5">
            <button class="btn btn-secondary btn-block" type="submit">Upload</button>
          </div>
        </form>
      </div>
      <div class="col-6">
        <form  @submit.prevent="submitHandle(2)">
          <label for="uploadFile2" class="form-label">Source code (zip)*</label>
          <div class="input-group">
            <input type="file" class="form-control" ref="uploadFile2" v-on:change="handleFileUpload2()">
          </div>
          <div v-if="uploadFileError2" class="small text-danger">
            {{ uploadFileError2 }}
          </div>
          <div class="form-group mt-3 mb-5">
            <button class="btn btn-secondary btn-block" type="submit">Upload</button>
          </div>
        </form>
      </div>
      <div class="col-6">
        <form  @submit.prevent="submitHandle(3)">
          <label for="uploadFile3" class="form-label">Prediction results (csv)*</label>
          <div class="input-group">
            <input type="file" class="form-control" ref="uploadFile3" v-on:change="handleFileUpload3()">
          </div>
          <div v-if="uploadFileError3" class="small text-danger">
            {{ uploadFileError3 }}
          </div>
          <div class="form-group mt-3 mb-5">
            <button class="btn btn-secondary btn-block" type="submit">Upload</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {Response} from "@/classes/response";

export default{
  name:'Qualifier',
  props:['globalData', 'pageId'],
  data(){
    return{
      uploadFile : null,
      uploadFileError : null,
      uploadFile2 : null,
      uploadFileError2 : null,
      uploadFile3 : null,
      uploadFileError3 : null,
    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    }
  },
  methods:{
    submitHandle(type){
      this.globalData.loader.visible = true

      //FILE READ
      let fileContent = null
      let reader = new FileReader();
      if(type===1){
        reader.readAsDataURL(this.uploadFile);
      }else if(type===2){
        reader.readAsDataURL(this.uploadFile2);
      }else if(type===3){
        reader.readAsDataURL(this.uploadFile3);
      }

      let that = this
      reader.onload = function (e) {
        //FILE READ OK
        fileContent = e.target.result

        let postData = {}
        if(type===1){
          postData = {
            'fileName' : that.uploadFile.name,
            'fileContent' : fileContent,
            'documentType' : type
          }
        }else if(type===2){
          postData = {
            'fileName' : that.uploadFile2.name,
            'fileContent' : fileContent,
            'documentType' : type
          }
        }else if(type===3){
          postData = {
            'fileName' : that.uploadFile3.name,
            'fileContent' : fileContent,
            'documentType' : type
          }
        }


        that.$axios.post('v1/user/upload',postData)
            .then((response) => {
              let responseData=new Response().handle(response)
              that.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})

              if(responseData.status===true) {
                if(type===1){
                  that.$refs.uploadFile.value = null
                }else if(type===2){
                  that.$refs.uploadFile2.value = null
                }else if(type===3){
                  that.$refs.uploadFile3.value = null
                }

              }
            })
            .catch(error => {
              console.log(error)
              let responseData =  new Response()
              responseData.errorHandle(error.response)
              that.globalData.alerts.push({msg: response.msg, type: response.msgType})
              that.globalData.loader.visible = false
            })
            .then(() => {
              that.globalData.loader.visible = false
            });
      }
      reader.onerror = function (evt) {
        that.globalData.loader.visible = false
        return false
      }
    },
    handleFileUpload(){
      this.uploadFile = this.$refs.uploadFile.files[0]
    },
    handleFileUpload2(){
      this.uploadFile2 = this.$refs.uploadFile2.files[0]
    },
    handleFileUpload3(){
      this.uploadFile3 = this.$refs.uploadFile3.files[0]
    }
  }
}
</script>

<style scoped>
</style>